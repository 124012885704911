


























































































































import { Component, Vue } from 'vue-property-decorator';
import GSignInButton from 'vue-google-signin-button';
import { appName } from '@/env';
import { googleClientId, slackClientId, gitHubClientId } from '@/env';
import { readLoginError } from '@/store/main/getters';
import { dispatchLogIn, dispatchSocialLogIn } from '@/store/main/actions';

Vue.use(GSignInButton);

@Component
export default class Login extends Vue {
  public email = '';
  public password = '';
  public appName = appName;
  public googleSignInParams = {
    client_id: googleClientId,
  };
  public githubSignin() {
    window.location.href = `https://github.com/login/oauth/authorize?client_id=${gitHubClientId}`;
  }
  public slackSignin() {
    window.location.href = `https://slack.com/oauth/v2/authorize?user_scope=identity.basic,identity.email&client_id=${slackClientId}`;
  }
  public get loginError() {
    return readLoginError(this.$store);
  }
  public async onSignInSuccess(googleUser) {
    const token = googleUser.getAuthResponse().id_token;
    const provider = 'google'; 
    await dispatchSocialLogIn(this.$store, { provider, token });
  }
  public onSignInError(error) {
    // This already get handled, but the component requires this function.
  }
  public submit() {
    dispatchLogIn(this.$store, {
      username: this.email,
      password: this.password,
    });
  }
}
